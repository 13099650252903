import React from 'react'
import { PropagateLoader } from 'react-spinners'
import './FullSpinner.css'

export default function FullSpinnerLostConn() {
    return (
        <div>
            <div className="connlost">
                CONNESSIONE PERSA
            </div>
            <div className="full-spinner-container">
                <div className="full-spinner-box">
                    <PropagateLoader
                        sizeUnit="px"
                        size={15}
                        color="#fff"
                        loading
                    />
                </div>
            </div>
        </div>
    )
}
