import React from 'react'
import Footer from './components/Footer'

export default function Landing() {
    return (
        <div className="container-fluid landing-container text-center">
            <div className="row landing-banner">
                <div className="col-12">
                    <img
                        src="orango_site_white_logo.svg"
                        alt="logo"
                    />
                </div>
            </div>
            <div className="row landing-buttons">
                <div className="col-12">
                    <a
                        className="btn btn-block btn-lg btn-orange"
                        href="?#/asd/login"
                        style={{
                            display: 'inline-block',
                            width: 400,
                        }}
                    >
                        Entra come amministratore
                    </a>
                </div>
                <div
                    className="col-12 text-center"
                >
                    oppure
                </div>
                <div className="col-12">
                    <a
                        className="btn btn-block btn-lg btn-orange"
                        href="?#/manager/login"
                        style={{
                            display: 'inline-block',
                            width: 400,
                        }}
                    >
                        Entra come responsabile o partner
                    </a>
                </div>
            </div>
            <Footer
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                }}
            />
        </div>
    )
}
