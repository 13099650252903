import React from 'react'

export default class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            style: props.style ? props.style : {},
        }
    }

    render() {
        return (
            <footer style={this.state.style}>
                <p>
                    Rag. soc.&nbsp;
                    <strong>Sport grand Tour SRL</strong>
                    &nbsp;&nbsp;Corso Stati Uniti 45, 10129 Torino
                    <br />
                    Cod. Fisc./P. IVA:&nbsp;
                    <strong>11661350014</strong>
                    &nbsp;-&nbsp;Cap. soc.: €15.184,14 I.V.
                </p>
            </footer>
        )
    }
}
