import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import './index.css'

import Routing from './Routing'

import * as serviceWorker from './serviceWorker'

require('dotenv').config()

ReactDOM.render(
    <ConfigProvider>
        <Routing />
    </ConfigProvider>,
    document.getElementById('root'),
)

serviceWorker.unregister()
