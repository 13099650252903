import React, { Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import Landing from './Landing'
import FullSpinner from './components/FullSpinner/FullSpinner'
import FullSpinnerLostConn from './components/FullSpinner/FullSpinnerLostConn'

const Logout = React.lazy(() => import('./pages/Logout'))
const LoginASD = React.lazy(() => import('./pages/LoginASD'))
const LoginManager = React.lazy(() => import('./pages/LoginManager'))
const Home = React.lazy(() => import('./pages/Home'))
const SummerCamp = React.lazy(() => import('./pages/SummerCamp'))
const Customfields = React.lazy(() => import('./pages/Customfields'))
const Managers = React.lazy(() => import('./pages/Managers'))
const Partners = React.lazy(() => import('./pages/Partners'))

export default class Routing extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isDisconnected: false,
        }

        this.handleConnectionChange = this.handleConnectionChange.bind(this)
    }

    componentDidMount() {
        this.handleConnectionChange()
        window.addEventListener('online', this.handleConnectionChange)
        window.addEventListener('offline', this.handleConnectionChange)
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange)
        window.removeEventListener('offline', this.handleConnectionChange)
    }

    handleConnectionChange = () => {
        setInterval(
            () => {
                fetch(`${process.env.REACT_APP_APIURL}/info/status`, {
                    mode: 'no-cors',
                }).then(() => {
                    this.setState({ isDisconnected: false }, () => {})
                }).catch(() => {
                    this.setState({ isDisconnected: true })
                })
            }, 10000,
        )
    }

    render() {
        return (
            <HashRouter>
                <Suspense fallback={<FullSpinner />}>
                    { this.state.isDisconnected ? <FullSpinnerLostConn /> : null }
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/landing" component={Landing} />
                        <Route exact path="/asd/login" component={LoginASD} />
                        <Route exact path="/manager/login" component={LoginManager} />
                        <Route exact path="/asd/home" component={Home} />
                        <Route exact path="/manager/home" component={Home} />
                        <Route exact path="/partner/home" component={Home} />
                        <Route exact path="/asd/customfields" component={Customfields} />
                        <Route exact path="/asd/managers" component={Managers} />
                        <Route exact path="/asd/partners" component={Partners} />
                        <Route exact path="/asd/summercamps/:summercampId/rounds/:roundId" component={SummerCamp} />
                        <Route exact path="/manager/summercamps/:summercampId/rounds/:roundId" component={SummerCamp} />
                        <Route exact path="/partner/summercamps/:summercampId/rounds/:roundId" component={SummerCamp} />
                        <Route component={Landing} />
                    </Switch>
                </Suspense>
            </HashRouter>
        )
    }
}
